import React from 'react'
import Header from '../Component/Header'
import Whatsapp from '../Component/Whatsapp'
import Footer from '../Component/Footer'
import { NavLink } from 'react-router-dom'
import TextAnimated from '../Component/TextAnimated'
import VerticalTextSlide from '../Component/VerticalTextSlide'
const slideText = ["Welcome to React Native Text Slider",

  "It is very easy to use",
  "You can simply install and use it now",
  "If you have any issues open up an issue in issues tab"
]
export default function LoaderScreen() {
  return (
    <div className='loader-screen-box'>
      <Header />
      <div className='content loader-content'>
        <div className='container'>
          <div className='loader-screen'>
            <h2 className='head'>We help you realise where you are presently in terms of</h2>
            {/* <div className="irotate">
                <div className="thisis slidem">
                  <div>simple</div>
                  <div>super easy</div>
                  <div>fun</div>
                  <div>working</div>
                </div>
              </div> */}
            <VerticalTextSlide />

            <NavLink to='/homepage' className='btn continue'>
              Continue
            </NavLink>
          </div>
        </div>
      </div>
      <Whatsapp />
      <Footer />
    </div>
  )
}
