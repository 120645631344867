import React from 'react'
import Card from 'react-bootstrap/Card'
import { useNavigate } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import { getRandomColor } from '../helpers/utils'
import cardImg1 from '../assets/images/card-img1.png'
import cardImg2 from '../assets/images/card-img2.png'
import cardImg3 from '../assets/images/card-img3.png'
import cardImg4 from '../assets/images/card-img4.png'
import cardImg5 from '../assets/images/card-img5.png'
import cardImg6 from '../assets/images/card-img6.png'
export default function CardSection({ item }) {
  let navigate = useNavigate()
  const colorCode = item?.text_colour ?? getRandomColor()


  return (
    <Col sm={4} xs={6}>
      <div style={{ backgroundColor: colorCode + 10 }} className='my-2'>
       <Card
          style={{ borderColor: colorCode }}
          onClick={() => {
            item?.next_answer
              ? navigate('/questions', {
                state: { color: colorCode, item: item },
              })
              : console.log('No Answers')
          }}
        >
          <Card.Body>
            <Card.Img
              src={
                item?.image
                  ? 'https://selfcheck-admin.hcsaspin.sg/' + item?.image
                  : cardImg1

              }
            />
            <Card.Title
              dangerouslySetInnerHTML={{
                __html:
                  item?.questions.length >= 180
                    ? item?.questions.slice(0, 180) + '...'
                    : item?.questions,
              }}
              style={{ color: colorCode }}
            >
              {/* <p>{item?.questions}</p> */}
            </Card.Title>
          </Card.Body>
        </Card>

      </div>
    </Col>
  )
}
