import axios from 'axios'
import React, { useState, useEffect } from 'react'
// const BASE_URL = 'http://192.168.45.186/lifecheck/public/api'
// const BASE_URL = 'https://phpx8.singsys.net/lifecheck/public/api'
 // const BASE_URL = 'http://localhost/lifecheck/public/api'

  const BASE_URL = "https://selfcheck-admin.hcsaspin.sg/api"


const useApi = ({ data = null, url = '', method = 'GET' }) => {
  const [state, setState] = useState({
    loadingStatus: 'not-loaded',
    data: [],
    error: null,
  })
  const callApi = async () => {
    try {
      setState({
        ...state,
        loadingStatus: 'loading',
      })
      const res = await axios({
        method: method,
        data: data,
        url: BASE_URL + url,
      })
      console.log('res', res.data)
      setState({
        ...state,
        loadingStatus: 'loaded',
        data: res.data ?? [],
      })
    } catch (e) {
      setState({
        ...state,
        loadingStatus: 'error',
        error: e?.response?.data ?? e?.message,
      })
    }
  }

  useEffect(() => {
    callApi()
  }, [])
  return {
    ...state,
  }
}

export default useApi
