import React, { memo, useState } from 'react'
import { useLocation } from 'react-router-dom'

// items ==> object
function AnswerButtonRow({ items, index }) {
  const [selected, setSelected] = useState(null)
  const [moreData, setMoreData] = useState(null)
  const location = useLocation()
  const colorcode = location.state?.color

  return (
    <>
      <div className='ans-box'>
        <ul className='nav nav-tabs'>
          {items?.map((item, index) => {
            return item?.next_answer ? (
              <li className='nav-item'>
                <button
                  style={{
                    border: `1px solid ${colorcode}`,
                    color: index == selected ? '#fff' : colorcode,
                    backgroundColor:
                      selected == index ? colorcode : colorcode + 10,
                  }}
                  type='button'
                  className='nav-link'
                  onClick={() => {
                    setSelected(index)
                    setMoreData(item?.next_answer ?? [])
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item?.answer,
                  }}
                ></button>
              </li>
            ) : (
              <div
                style={{
                  padding: 5,
                  alignItems: 'center',
                  marginBottom: '10px',
                  marginLeft: '5px',
                }}
                className='ans-text'
                dangerouslySetInnerHTML={{
                  __html: item?.answer,
                }}
              />
            )
          })}
        </ul>
      </div>

      {console.log('More data', moreData)}
      {!!moreData && <MemoAnswerButton items={moreData} />}
    </>
  )
}
const MemoAnswerButton = memo(AnswerButtonRow)
export default MemoAnswerButton
