import React from 'react'
import Row from 'react-bootstrap/Row'
import cardImg1 from '../assets/images/card-img1.png'
import cardImg2 from '../assets/images/card-img2.png'
import cardImg3 from '../assets/images/card-img3.png'
import cardImg4 from '../assets/images/card-img4.png'
import cardImg5 from '../assets/images/card-img5.png'
import cardImg6 from '../assets/images/card-img6.png'
import CardSection from '../Component/CardSection'
import Footer from '../Component/Footer'
import Header from '../Component/Header'
import Whatsapp from '../Component/Whatsapp'
import useApi from '../helpers/useApi'

export default function HomePage() {
  //Call Api for Data
  const { data, loadingStatus, error } = useApi({
    method: 'GET',
    url: '/answer_details',
  })


  return (
    <div className='loader-screen-box'>
      <Header />
      <div className='content loader-content'>
        <div className='container'>
          <div className='loader-screen2'>
            <h2 className='head'>What area of support do you need help in ?</h2>
            <p className='title'>Select one area</p>
            <Row lg={3} md={3}>
              {data.map((item, i) => {
                return <CardSection key={i} item={item} />
              })}
            </Row>
          </div>
        </div>
      </div>
      <Whatsapp />
      <Footer />
    </div>
  )
}
