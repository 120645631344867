import React, { useState } from 'react'
import { useEffect } from 'react'
import LoaderScreen from './LoaderScreen'
import SplashScreen from './SplashScreen'

export default function LandingScreen() {
  const [isSplash, setIsSplash] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setIsSplash(false)
    }, 3000)
  }, [])
  return isSplash ? <SplashScreen /> : <LoaderScreen />
}
