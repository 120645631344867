import React, { useEffect, useRef, useState } from 'react'

export default function VerticalTextSlide() {

    const [words, setWords] = useState(['psychological', 'economical', 'social'])

    const wrapperRef = useRef()

    const handleAnim = () => {

        setTimeout(() => {
            const copyWords = [...words];
            const firstElem = copyWords.splice(1)
            wrapperRef.current.style.transition = '0s';
            wrapperRef.current.style.top = '0px'
            setWords([...firstElem.concat(copyWords)])
        }, 500);

        wrapperRef.current.style.transition = '0.5s';
        wrapperRef.current.style.top = '-70px'

    }
    useEffect(() => {
        setTimeout(() => {
            handleAnim()
        }, 2000);
    })

    return (
        <div className="ver-text-container">
            <div className='ver-text-title'>needs</div>
            <div className='ver-text-container-word'>
                <div ref={wrapperRef} className='ver-text-container-word-wrapper'>
                    <span className='ver-text-word' data-class={words[0]}>{words[0]}</span>
                </div>
            </div>
        </div>
    )
}
