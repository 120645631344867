import React from 'react'
import Typewriter from 'typewriter-effect'
export default function TextAnimated() {
  return (
    <div className='text'>
      <Typewriter
        options={{
          // strings: ['Hello', 'World'],
          autoStart: true,
          loop: true,
        }}
        onInit={(typewriter) => {
          typewriter
            .typeString('<span style="color:#F05D56;">psychological</span>')
            .pauseFor(2500)
            .deleteAll()
            .typeString('<span style="color:#8559B7;">economical</span>')
            .pauseFor(2500)
            .deleteAll()
            .typeString('<span style="color:#03A9F4;">social</span>')
            .pauseFor(2500)
            .deleteAll()
            .start()
        }}
      />
    </div>
  )
}
