import React from 'react'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import circleImg from '../assets/images/circle-back-arrow.png'
import { useState } from 'react'
import QuestionTab from '../Component/QuestionTab'
import Whatsapp from '../Component/Whatsapp'
import { useLocation, useNavigate } from 'react-router-dom'

export default function QuestionPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const colorCode = location.state.color
  const preState = location.state?.item
  console.log('preState', preState)

  const [selected, setselected] = useState(preState?.next_answer[0].answer)

  const [activetab, setactivetab] = useState(`${preState?.next_answer[0].id}`)

  return (
    <div className='quspage-whats'>
      <Header />
      <div
        style={{ backgroundColor: colorCode + 10 }}
        className='content justify-content'
      >
        <div className='container'>
          <div className='card-detail'>
            <div className='circle-img'>
              <Button variant='link' onClick={() => navigate(-1)}>
                <img src={circleImg} alt='' />
              </Button>
            </div>
            <Tab.Container id='left-tabs-example' defaultActiveKey={activetab}>
              <Row>
                <Col lg={4}>
                  <div className='card-left-detail'>
                    <h2
                      className='head'
                      style={{ color: colorCode }}
                      dangerouslySetInnerHTML={{ __html: preState?.questions }}
                    ></h2>
                    <p className='title' dangerouslySetInnerHTML={{ __html: preState?.description??"-" }} >
                      
                    </p>

                    <Row className='left-tab'>
                      <Col md={12}>
                        <Nav
                          // onClick={(value) => {
                          //   setselected(value?.target?.['innerText'])
                          // }}
                          onSelect={(v) => {
                            setactivetab(v)
                          }}
                          variant='pills'
                        >
                          {preState?.next_answer?.map((navitem, navindex) => {
                            return (
                              <Nav.Item key={navitem?.id}>
                                <Nav.Link
                                  onClick={(value) => {
                                    console.log('Selected', value.target)
                                    setselected(value?.target?.['innerText'])
                                  }}
                                  style={{
                                    color:
                                      navitem?.id == activetab
                                        ? '#fff'
                                        : colorCode,
                                    border: `1px solid ${colorCode}`,
                                    backgroundColor:
                                      navitem.id == activetab
                                        ? colorCode
                                        : colorCode + 10,
                                  }}
                                  eventKey={`${navitem?.id}`}
                                  dangerouslySetInnerHTML={{
                                    __html: navitem?.answer,
                                  }}
                                ></Nav.Link>
                              </Nav.Item>
                            )
                          })}
                        </Nav>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={8}>
                  <div
                    style={{ border: `1px solid ${colorCode}` }}
                    className='card-right-detail'
                  >
                    <div
                      className='breadcrumb'
                      style={{ color: colorCode }}
                      dangerouslySetInnerHTML={{
                        __html:
                          preState?.questions + '&nbsp  >  &nbsp ' + selected,
                      }}
                    ></div>
                    <p className='text'>Click on your answers</p>
                    <Row>
                      <Col md={12}>
                        <Tab.Content>
                          {preState?.next_answer.map((item, i) => {
                            return (
                              <Tab.Pane key={item?.id} eventKey={`${item?.id}`}>
                                <QuestionTab
                                  tabdata={item?.next_answer ?? []}
                                />
                              </Tab.Pane>
                            )
                          })}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </div>
      </div>

      <Whatsapp />
      <Footer />
    </div>
  )
}
