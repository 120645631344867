import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LandingScreen from './Screens/LandingScreen'
import HomePage from './Screens/HomePage'
import NotFound from './Component/NotFound'
import QuestionPage from './Screens/QuestionPage'

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<LandingScreen />}></Route>
        <Route path='/homepage' element={<HomePage />}></Route>
        <Route path='/questions' element={<QuestionPage />}></Route>
        <Route path='/*' element={<NotFound />}></Route>
      </Routes>
    </Router>
  )
}

export default App
