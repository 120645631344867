import React, { memo } from 'react'
import colorLogo from '../assets/images/color-logo.png'
import heartImg from '../assets/images/heart-img.png'
import backImg from '../assets/images/back-arrow.png'

const Header = () => {
  return (
    <header
      style={{ boxShadow: '0 3px 15px rgb(0 0 0 / 0.1)', paddingBottom: 10 }}
      className='header'
    >
      <div className='container'>
        <div className='d-flex align-items-center justify-content-between header-innerBox'>
          <div className='left-content'>
            <img src={colorLogo} alt='' />
          </div>
          <div className='right-content'>
            <p>
            <a href='https://www.hcsaspin.sg/'>
              <img src={heartImg} alt='' />
              </a>
              SelfCheck Tool
            </p>
          </div>
        </div>
        <p className='back-arrow'>
          <img src={backImg} alt='' />
         <a href='https://www.hcsaspin.sg/'> Back to main site</a>
        </p>
      </div>
    </header>
  )
}

export default memo(Header)
