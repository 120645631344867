import React, { memo } from 'react'
import MemoAnswerButton from './AnswerButtonRow'

const QuestionTab = ({ tabdata }) => {
  // For boxes (1->2->3)
  // How many question box..
  return tabdata.map((data, i) => {
    return (
      <div key={data?.id} className='qus-box'>
        <h2
          dangerouslySetInnerHTML={{
            __html: data?.answer,
          }}
        ></h2>
        <MemoAnswerButton items={data?.next_answer ?? []} />
        {/* 
        <div className='ans-box'>
          <ul className='nav nav-tabs'>
            {data?.next_answer?.map((i, index) => (
              <AnswerButtonRow key={index} items={i} index={index} />
            ))}
          </ul> 
        </div> */}
      </div>
    )
  })
}
export default memo(QuestionTab)
