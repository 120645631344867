import React from 'react'
import logo from '../assets/images/logo.png'
import handshake from '../assets/images/handshake.gif'
import Footer from '../Component/Footer'

export default function SplashScreen() {
  return (
    <div className='wrapper'>
      <header className='header'>
        <div className='container'>
          <img src={logo} alt='' />
        </div>
      </header>
      <div className='content'>
      
        <div className='container'>
        <div className='handshake-img text-center mb-3'>
            <img src={handshake} width='150px' alt='loading' />
          </div>
          <div className='welcome-content-box'>
            <h2 className='head'>Welcome to Selfcheck Tool</h2>
            
            <p>Wonder what benefits you may be entitled to as a single parent or solo caregiver? Or want to know which other avenues of help are available to you?</p>
           
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
