import React, { memo } from 'react'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <p>&copy; 2022, HCSA (SelfCheck Tool). All Rights Reserved.</p>
      </div>
    </footer>
  )
}
export default memo(Footer)
