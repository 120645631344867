import React from 'react'
import whtsp from '../assets/images/whatsapp.png'
import call from '../assets/images/call.png'
import doc from '../assets/images/doc.png'

export default function Whatsapp() {
  return (
    <div className='whatsapp-img-box'>
      <div class="help-box">
        <span class=" help-btn">
          Need Help ?
        </span>
        <ul class="help-menu">
          <a target="_blank" href="https://api.whatsapp.com/send?phone=6587870230"><li><img src={whtsp}/><span>Whatsapp</span></li></a>
           <a target="_blank" href="tel:+6587870230"><li><img src={call}/><span>Call Us</span></li></a>
          <a target="_blank" href="mailto: dayspring_spin@hcsa.org.sg"><li><img src={doc}/><span>Email Us</span></li></a>
         
        </ul>
      </div>
    </div>
  )
}
